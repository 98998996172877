<template>
  <FormWrapper
    :form="form"
    :hideForm="hideForm"
    :handleOpened="() => handleProps({ key: 'opened', value: !form.opened })"
    attachModule="bcTesteIshihara"
  >
    <IshiharaResume :loading="loading" :ishihara="this.form.fields" />
  </FormWrapper>
</template>
  
  <script>
  import { mapState, mapActions, mapGetters } from 'vuex'
  import attendanceDataMixin from '@/mixins/Attendance/attendanceDataMixin'

  export default {
    components: {
      FormWrapper: () => import('@/components/Attendance/Forms/FormWrapper'),
      IshiharaResume: () => import('@/components/Attendance/Forms/Components/IshiharaResume'),
    },
    computed: {
      ...mapState({
        canEdit: state => state.attendance.canEdit,
        form: state => state.attendance.form.bvTesteIshihara,
        medicalRecordsStore: state => state.attendance.medicalRecordsStore,
        attendance: state => state.attendance.attendance,
      }),
      ...mapGetters('attendance', ['bvConsultation'])
    },
    mixins: [attendanceDataMixin('medicalRecordsStore')],
    data() {
      return {
        loading: false,
        ishihara: null
      }
    },
    methods: {
      ...mapActions('attendance/form', ['handleActiveModule', 'updateMedicalRecord', 'destroyMedicalRecord']),
      ...mapActions('attendance/form/bvTesteIshihara', ['handleFields', 'handleProps']),
      updateForm(key, value) {
        this.handleFields({ key, value })
      },
      hideForm(){
        this.handleProps({ key: 'showing', value: false })
        this.destroyMedicalRecord(this.form)
        Object.keys(this.form.fields).forEach(key => {
          this.form.fields[key] = null
        });
      },
      async getBv() {
        const ishihara = this.bvConsultation?.ishihara?.response
        if (!ishihara) return
        this.form.fields.hits = ishihara.hits
        this.form.fields.greenDeficiency = ishihara.greenDeficiency
        this.form.fields.redDeficiency = ishihara.redDeficiency
        this.form.fields.redGreenDeficiency = ishihara.redGreenDeficiency
        this.form.fields.miss = ishihara.miss
        this.form.fields.hitsList = ishihara.hitsList
        this.form.fields.missList = ishihara.missList
        this.form.fields.diagnosysLabel = ishihara.diagnosysLabel
        this.form.fields.answers = ishihara.answers
        this.form.fields.createdAt = this.bvConsultation?.ishihara?.createdAt
        this.updateMedicalRecord(this.form)
      },
    },
    watch: {
      'bvConsultation': function (value) {
        if(value){
          this.getBv()
        }
      }
    },
  }
</script>
<style lang="scss" scoped>

.subtitle {
    color: var(--type-active, #525C7A);
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    margin-top: 0.6rem;
}
</style>